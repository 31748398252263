<template>
    <div>
<!--        <div v-if="map_loading" class="alert alert-info mb-3">-->
<!--            Loading map. Please wait.-->
<!--        </div>-->
<!--        <div v-if="message" class="alert alert-info mb-3">-->
<!--            {{ message }}-->
<!--        </div>-->

        <div class="response-map">
            <div id="map" :code="code">Map</div>
        </div>
    </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";

export default {
    name: "Map",
    props: ['code'],
    data() {
        return {
            center: [0.31967, 35.17580],
            markers: [],
            markerClusters: null,
            userLocation: null,
            locations: [],

            map_loading: false,
            message: ''
        };
    },
    mounted() {
        this.setupLeafletMap();
    },
    methods: {
        setupLeafletMap: function () {
            // Map Init
            const mapDiv = L.map("map").setView(this.center, 4);

            L.tileLayer("https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}", {
                    attribution: this.getAttribution(),
                    id: "mapbox/streets-v11",
                    outerHeight: "80vh",
                    accessToken: process.env.VUE_APP_MAP_KEY,
                }
            ).addTo(mapDiv);

            let self = this
            // Load projects geo gps
            this.map_loading = true
            this.$http.get(`/api/profile/survey/${this.code}/geo`).then((response) => {
                let geo = response.data.data
                // Center with the first coordinate
                let first = geo[0]
                if (first) {
                    this.center = first.geo
                }

                for (var i = 0; i < geo.length; i++) {
                    if (geo[i].geo != null) {
                        let tag =
                            '<a class="text-info font-weight-bold" href="#")">' + geo[i].code + "</a><br>" +
                            '<span class="text-muted">' + geo[i].geo + "</span><br>" +
                            "<b >CODE:</b>" + geo[i].code +
                            "<br>" + geo[i].created_at

                        this.markers = new L.marker(geo[i].geo.split(','), {
                            icon: this.getIcon(),
                        }).bindPopup(tag).addTo(mapDiv).on('click', function (event) {
                            self.$emit('tagClicked', event.latlng.lat + ',' + event.latlng.lng)
                        })
                    }
                }
            }).catch(() => {
                this.message = "Sorry an error occurred while loading map";
            }).then(() => {
                this.map_loading = false
            });
        },
        getAttribution() {
            return 'Map data (c) <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery (c) <a href="https://www.mapbox.com/">Mapbox</a>'
        },
        getIcon() {
            return L.icon({
                iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
                iconAnchor: [22, 94],
                popupAnchor: [-3, -76],
                shadowUrl: "",
                shadowSize: [68, 95],
                shadowAnchor: [22, 94],
                className: 'blinking'
            });
        }
    },
}
</script>


<style scoped>
/*.response-map {*/
/*    height: 100%;*/
/*    overflow: hidden;*/
/*    position: relative;*/
/*}*/

#map {
    height: 100vh;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    position: absolute;
    z-index: 0;
}

@keyframes fade {
    from {
        opacity: 0.5;
    }
}

.blinking {
    animation: fade 1s infinite alternate;
}
</style>