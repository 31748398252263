<template>
    <div class="row g-2">
        <div class="col-lg-12">
            <Map :code="code"/>
        </div>
    </div>
</template>

<script>
import Map from "@/components/Views/Helpers/Map.vue"

export default {
    name: "SurveyMap",
    props: ['code'],
    components: {Map},
    data() {
        return {
            survey: {},
            respondents: [],
            respondent: '',
        }
    },
    mounted() {
        this.$http.get("/api/profile/survey/" + this.code).then(response => {
            this.survey = response.data.data
            this.loadRespondents()
        }).catch(error => {
            this.showError(error)
        })
    },
}
</script>

<style scoped>
.project-map-card {
    background-color: #fff;
    border: 1px solid darkgrey;
    border-radius: 5px;
    box-shadow: 0 2px 4px #010101;
    margin: 0 0 0 auto;
    padding: 20px;
    position: relative;
    right: 8%;
    top: 65%;
    /*transform: translateY(-50%);*/
    width: 400px;
    z-index: 20;
}
</style>